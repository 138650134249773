@charset "utf-8";
@import "setting";
@import "components/reset";
@import "addon/magnific-popup";
@import "addon/wordpress";

/*----------------------
  common
----------------------*/

a {
  color: $mainC;
  /* text-decoration:none; */
  &:hover {
    color: $mainC;
    text-decoration: none;
  }
}

.list-mark {
  text-indent: -1em;
  padding: 0 0 0 1em;
  overflow: hidden;
}

/*----------------------
  common
----------------------*/

@media print, screen and (min-width: $w-sp + 1) {
  @import "pc";
}

/*end query*/
@media screen and (max-width: $w-sp) {
  @import "sp";
}
