@charset "utf-8";
//============================================================
//    基本設定
//============================================================
.sp {
  display: none !important;
}
.mb-l {
  margin-bottom: 50px;
}
.mb-m {
  margin-bottom: 30px;
}
.mb-s {
  margin-bottom: 15px;
}
.header {
  padding: 30px;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 300px;
  height: 100%;
  z-index: 10;
  box-sizing: border-box;
  z-index: 2;
  opacity: 0;
  background: transparent;
  transition: all $easeInSine 500ms;
  background: $mainC;
  
  &.show {
    opacity: 1;
  }

  .logo {
    width: 100%;
    margin:0 auto 40px;
    padding: 10px;
    text-align: center;
    box-sizing: border-box;
    background: #fff;
    a {
      img {
        width: 100%;
      }
      span{
        text-align: center;
        font-size: 12px;
        display: inline-block;
        font-family: $wf1;
        font-style: italic;
        font-weight: normal;

      }
    }
    
  }
  .menu-trigger {
    span {
    }
  }
  .gnav {
    ul {
      margin-bottom: 30px;
      li {
        padding: 0;
        margin-bottom: 10px;
        a {
          display: block;
          padding: 20px 0 10px 50px;
          font-size: 14px;
          position: relative;
          overflow: hidden;
          color:#fff;
          &:before{
            content: '';
            width: 10px;
            height: 1px;
            background: #fff;
            position: absolute;
            transform: rotate(45deg);
            left: 20px;
            top:42px; 
          transition: all $easeInSine 200ms;

          }
          &::after{
            content: '';
            width: calc(100% - 30px);
            height: 1px;
            background: #fff;
            position: absolute;
            left: 28px;
            top:45px; 
          transition: all $easeInSine 200ms;

          }
          span{
            position: absolute;
            font-family: $wf1;
            border:solid 1px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            font-size: 18px;
            text-align: center;
            display: inline-block;
            left: 0;
            top: 0;
            font-weight: normal;
            font-style: italic;
            transition: all $easeInSine 200ms;
          }

          &.now{
            // background: #000;
            
          }
          
          &:hover,
          &.now {
            font-weight: bold;
            span{
              color:$mainC;
              border: 1px solid $hoverC;
              background: $hoverC;
            }
            color:$hoverC;
            // background: #fff;
            &:before{
              background: $hoverC;
              left: 30px;

            }
            &:after{
              background: rgb(255, 255, 230);
              left: 38px;

            }

          }
          
        }
      }
    }
    @keyframes bg-color {
      0% { background-color: #e74c3c; }
      30% { background-color: #f1c40f; }
      60% { background-color: #1abc9c; }
      70% { background-color: #f1c40f; }
      100% { background-color: #e74c3c; }
    }
    @keyframes bg-arrow {
      0% { transform: translateX(0px) rotate(135deg); }
      50% { transform: translateX(0px) rotate(135deg); }
      60% { transform: translateX(10px) rotate(135deg); }
      100% { transform: translateX(0px) rotate(135deg); }

    }
    .entry{
      display:block;
      // background: linear-gradient(to right, #b1fd95 24%, #60ec27 100%);
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#054a90', endColorstr='#54c0f2', GradientType=1 );
      animation: bg-color 10s infinite;
      padding: 20px;
      box-sizing: border-box;
      font-weight:bold;
      height: 100%;
      color: #fff;
      font-size: 16px;
      text-shadow:1px 1px 3px #535353;
      position:relative;
      line-height: 16px;
      &:after{
        content:"";
        width:15px;
        height:15px;
        border-top:solid 2px #fff;
        border-left:solid 2px #fff;
        // transform:rotate(135deg);
        display:inline-block;
        margin-left:20px;
        animation: bg-arrow 4s infinite;
      }
    }
  }
}

body {
  background: #fff;
  line-height: 2;
  color: $normalC;
  font-size: 16px;
  letter-spacing: 0.14em;
  font-family: $fg;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  overflow-x: hidden;
  a {
    text-decoration: none;
    color: $mainC;
    transition: $ease 300ms;
    &:hover {

    }
  }
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $mainC;
  z-index: 3;
  transition: opacity $easeInSine 900ms;
  opacity: 1;
  .message{
    position: absolute;
    width: 100%;
    height: 1em;
    top: calc(50% - 40px);
    left: 0;
    text-align: center;
    font-family: $wf1;
    color: #fff;
    font-size: 14px;
    font-style: italic;
  }
  .progress {
    position: absolute;
    width: 0;
    height: 1px;
    background: #fff;
    top: calc(50% - 1px);
    left: 0;
    transition: opacity $easeInSine 900ms;
    
  }
  &.end {
    .progress {
      opacity: 0;
    }
  }
  &.hide {
    z-index: -1;
    opacity: 0;
  }
}
.first-wrap {
  position: absolute;
  // overflow: hidden;
  height: 100vh;
  width: calc(100% - 300px);
  box-sizing: border-box;
  opacity: 0;
  transition: opacity $ease 5000ms;
  &.show {
    opacity: 1;
  }
  .video-cover{
    background: linear-gradient(to right, rgba(87, 193, 242, 0.5) 24%, rgba(8, 152, 218, 0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#054a90', endColorstr='#54c0f2', GradientType=1 );
    width:100%;
    height:100%;
    top:0;
    left:0;
    position: absolute;
  }
  .first-view {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    video {
      width: auto;
      height: 100%;
    }

  }
}
.top-contents {
  position: relative;
  box-sizing: border-box;

  .contents-floor-wrap {
    padding: 100px 350px 100px 50px;
    box-sizing: border-box;
    position: relative;
    border-bottom: 3px solid $mainC;
    &.rec-cont-00{
      padding: 0px 300px 0px 0px;
      border-bottom: none;
      .inner{
        padding: 60px 50px 0;
        .contents-list{
          position: relative;
          display: flex;
          flex-wrap: wrap;
          z-index: 1;
          li{
            width: 50%;
            padding: 20px;
            a{
              display: flex;
              background: rgba(255,255,255,0.7);
              img{
                width: 180px;
                height: auto;
              }
              .detail{
                padding: 15px;
                .title{
                  font-weight: bold;
                  margin-bottom: 15px;
                  color: $mainC;
                }
                .detail-text{}
              }
            }
          }
        }
      }

    }
    &:nth-child(even){
      // background: #eaf8fa;
    }
    .head-line {
      color: $normalC;
      margin-bottom: 50px;
      display: flex;
      align-items: center;
      &.c-w {
        h1{
          span{
            color: #fff;
            &:after{
              background: #fff;
            }
          }
        }
        p{
          color: #fff;
        }
      }
      &.loaded {
        h1 {
          span {
            transform: translateY(0);
            opacity: 1;
            &:after {
              transform: scaleX(1);
            }
          }
        }
        p {
          transform: translateX(0);
          opacity: 1;
        }
      }
      h1 {
        font-family: $wf1;
        font-size: 60px;
        margin-bottom: 15px;
        margin-right: 30px;
        line-height: 60px;
        font-style: italic;
        overflow: hidden;

        span {
          padding: 0px 5px 5px 5px;
          display: inline-block;
          position: relative;
          opacity: 0;
          transform: translateY(60px);
          transform-origin: center bottom;
          transition: transform $easeInSine 300ms 300ms;
          color: $mainC;
          &:after {
            content: "";
            width: 100%;
            height: 4px;
            display: block;
            bottom: 0;
            left: 0;
            position: absolute;
            background: $mainC;
            transform: scaleX(0);
            // transform-origin: left top;
            transition: transform $easeInSine 500ms 800ms;
          }
        }
        
      }
      p {
        transform: translateX(30px);
        transition: all $easeInSine 600ms 600ms;
        opacity: 0;
        &.c-w {
          color: #fff;
        }
      }
    }

    .inner {
      margin: 0 auto;
      min-width: 800px;
      .main-title {
        font-size: 32px;
        letter-spacing: 0.1em;
        margin-bottom: 15px;
        font-weight: normal;
      }
    }
  }
  .door{
    position: fixed;
    top: 0;
    right: 300px;
    width: calc(100% - 300px);
    height: 100%;
    z-index: 1;
    &.show{
      .left{
        left:-50%;
      }
      .right{
        right: -50%;
      }
    }
    &.hide{
      z-index: -1;
    }
    .door-block{
      width: 50%;
      position: absolute;
      top: 0;
      height:100%;
      background: rgba(255, 255, 255, 0.8);
      transition: all $easeInSine 500ms;
      &:after{
        content: '';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        // top: 15px;
        // left: 15px;
        // width: calc(100% - 30px);
        // height: calc(100% - 30px);
        // background: rgba(236, 253, 255, 0.4);
        background: rgba(5, 74, 144, 0.8);
        // border: 1px solid $mainC;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.4)
      }
    }
    .left{
      left: 0;
      border-right: 1px solid $mainC;

    }
    .right{
      right: 0;
      border-left: 1px solid $mainC;

    }
  }

  .base-table {
    width: 100%;
  }
  .base-table tbody > tr:nth-child(odd) {
    background: #e2f6ff;
  }
  .base-table tbody > tr:nth-child(even) {
    background: #FFFFFF;
  }
  .base-table tbody > tr > th, .base-table tbody > tr > td {
    padding: 20px;
    text-align: left;
    vertical-align: top;
    font-size: 16px;
    background: none;
  }
  .base-table tbody > tr > th {
    font-weight: bold;
    vertical-align: middle;
    white-space: nowrap;
    
  }
  .base-table tbody > tr > td p {
    font-feature-settings: "palt";
    line-height: 1.75;
  }
  .base-table tbody > tr > td p + dl {
    margin-top: 15px;
  }
  .base-table tbody > tr > td p + p {
    margin-top: 10px;
  }
  .base-table tbody > tr > td .mini-text {
    font-size: 14px;
    line-height: 1.714;
  }
  .base-table td ul {
    padding-left: 1em;
  }
  .base-table td ul li {
    list-style: square;
    font-size: 14px;
    line-height: 1.5;
  }
  .base-table td ul li strong {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .base-table dl {
    display: -ms-flexbox;
    display: flex;
    border: solid 1px #251B17;
    font-size: 13px;
  }
  .base-table dl + dl {
    border-top: none;
  }
  .base-table dl dt {
    width: 100px;
    padding: 15px 15px;
    background: #f2fafe;
  }
  .base-table dl dd {
    width: calc(100% - 100px);
    border-left: solid 1px #251B17;
    padding: 15px 15px;
    background: #FFFFFF;
  }
  .border-table {
    width: 100%;
  }
  .border-table th, .border-table td {
    padding: 10px 10px;
    text-align: left;
    vertical-align: middle;
    font-size: 13px !important;
    border: solid 1px #251B17;
  }
  .border-table th {
    font-weight: normal;
    white-space: nowrap;
  }
  .border-table td p {
    font-feature-settings: "palt";
    line-height: 1.75;
  }
  .border-table td p + p {
    margin-top: 10px;
  }
  .movie-wrap{
    .movie-list{
      li{
        padding-bottom: 60px;
        border-bottom: 1px solid $mainC;
        margin-bottom: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .movie{
          text-align: center;
          margin-bottom: 30px;
          width: 560px;
          iframe{

          }
        }
        .detail{
            width: calc(100% - 590px);
            text-align: center;

          .photo{
            border: 2px solid $mainC;
            height: 180px;
            width: 180px;
            margin: 0 auto 30px;
            border-radius: 90px;
            overflow: hidden;
            img{
              height: 100%;
              width: auto;
            }
          }
          .title{
            font-weight: bold;
            margin-bottom: 10px;
            font-size: 16px;
            color: $mainC;
          }
          .busho{
            margin-bottom: 10px;
            font-size: 14px;
          }
         
        }
        .detail-text{
          width: 100%;
        }
      }
    }
  }
  .rec-cont-01{
    .rec-01-detail{
      position: relative;
      &:after{
        content:"";
        position:absolute;
        left:20px;
        top:20px;
        width:97%;
        height:100%;
        background:#eee;
        border-radius:10px;
        }
    }
    .rec-01-table-wrap{
      border:solid 3px #bdeaff;
      border-radius:10px;
      background:#fff;
      margin:30px 30px 60px 0;
      position:relative;
      z-index:1;
      
      
      .rec-01-table{
      width:100%;
      th{
        width:300px;
        .photo{
          width:300px;
          height:300px;
          overflow:hidden;
          border-radius:150px;
          border:solid 6px #bceaff;
          img{
            display:inline-block;
            width:100%;
            height:auto;
            }
          }
        }
        td{
          padding:20px;
          box-sizing:border-box;
          }
      }
    }

    .rec-02-table{
      width: 100%;
      tr{
        border-bottom:solid 1px #ccc;
        td,
        th{
          padding: 10px;
          vertical-align: top;
        } 
        th{
          width: 15%;
        }
        td{
          width: 85%;
          }
        }
    }
      
    .rec-03-detail{
      .rec-03-interview{
    overflow: hidden;
    dt{
      font-size: 20px;
      font-style: italic;
      font-weight: bold;
      color: #f3a00c;
      margin-bottom: 20px;
      /* position: absolute; */
      &:before{
        content:"Q.";
        display:inline-block;
        font-size:30px;
        }
      }
      dd{
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 30px;
        border-bottom: solid 3px #bdeaff;
        margin: -80px 0 30px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .photo {
          /* float: right; */
          width: 300px;
          height: 300px;
          overflow: hidden;
          border-radius: 150px;
          border: solid 6px #bceaff;
          /* transform: translateY(-50px); */
          img{
            width: 100%;
            height: auto;
            }
        }
        .anser{
          /* float:left; */
          width: 60%;
          position:relative;
          border: 3px solid #bdeaff;
          background-color: #FFFFFF;
          border-radius: 6px;
          padding: 20px;
          box-sizing: border-box;
          margin: 0px 0 0 50px;
          &:before,
          &:after {
            position: absolute;
            top: 36%;
            right: -30px;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            border-width: 14px;
            transform: rotate(-90deg);
          }
          &:before {
            border-top-color: #ffffff;
            right: -26px;
            z-index:1;
          }
          &:after {
            border-top-color: #bdeaff;
          }
          }
        }
      }
    }
      
  }

  .rec-cont-03{
    .recruit-contents-detail{
      overflow: hidden;
      border: solid 3px #22a3e1;
      padding: 20px;
      box-sizing: border-box;
      .message-photo{
        float: left;
        margin-right: 20px;
        width:300px;
        img{
          width:100%;
          height:auto;
        }
      }
      p{
        margin-bottom:10px;
      }
      .recruit-contents-textbox{
        float:right;
        width:calc(100% - 320px);
      }
      .recruiter{
        text-align:right;
        font-size:12px;
      }
      .message-title{
        font-size: 18px;
        font-weight: bold;
        color: #22a3e1;
      }
    }
  } 
  
  .fb-box{
    text-align: center;margin: 30px auto;
    .fb-title{font-size: 18px;margin-bottom: 30px;}
    .fb-page{
      width:500px;
      margin:0 auto;
      }
    }
  
.inner{
  .tab-link{
    display: flex;
    li{
      padding: 0 15px;
      background:#d1f8ff;
      font-weight: 18px;
      font-weight: bold;
      text-align: center;
      height: 40px;
      line-height: 40px;
      box-sizing: border-box;
      position: relative;
      margin-right: 20px;
      box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
      &:after{
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        border-left: 20px solid #d1f8ff;
        border-top: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #d1f8ff;
        right: -40px;
        top: 0;
      }
      // &:nth-child(even){
      //   background: #d1f8ff;
      // &:after{
      //   border-left: 20px solid #d1f8ff;
      //   border-top: 20px solid transparent;
      //   border-right: 20px solid transparent;
      //   border-bottom: 20px solid #d1f8ff;
      // }
      // }
      &:hover,
      &.active{
        background: $mainC;
        color: #fff;
        &:after{
        border-left: 20px solid $mainC;
        border-top: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid $mainC;
        }
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
  .tab-contents-wrap{
    .tab-contents{
      display: none;
      &.show{
        display: block;
        border:solid 3px $mainC;
        padding:15px;
      }
    }
  }
  .recruit-contents-detail{
    overflow: hidden;
  }
  .rec-title{
    background: none;
    font-size: 27px;
    padding:10px;
    margin-bottom:20px;
    color: #f4b846;
    font-weight: bold;
    letter-spacing: 0.2em;
    text-align: center;
    position:relative;
    transition: all 300ms;
   
    &:after{
      content:"";
      display:block;
      width:240px;
      height:6px;
      margin:-3px auto 0;
      background:#f5b846;
      }
    
    }
    .rec-01-dl{
      overflow:hidden;
      width: 100%;
      font-size: 16px;
      color: #303131;
      dt{
        float:left;
        width: 20%;
        font-weight: bold;
        color:#054a90;
      }
      dt,
      dd{
        padding:20px 10px;
        border-bottom:solid 3px #bdeaff
      }
    }
    .rec-02-detail{
      border: solid 3px #f6b846;
      border-radius: 10px;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 60px;
      background: #fff;
      .rec-02-table{
      width:100%;
      margin-bottom:30px;
      th{
        width:30%;
        color: #f3a00b;
        font-size: 18px;
        font-weight: bold;
      }
      td{
        width:70%;
        font-size: 16px;
        font-weight:bold;
        span{
          display:block;
          font-size:14px;
          font-weight:normal;
          }
        }
        th,td{
        border-bottom: solid 3px #efefef;
        padding:20px;
        img{
          width:100%;
          height:auto;
          }
        }
      }
    }

  }
}


.footer {
  position: relative;
  padding: 50px 50px;
  background: #000;

  .page-top {
    display: none;
    a {
      span {
      }
    }
  }
  .footer-inner {
    width: 800px;
    display: flex;
    margin: 0 auto;
    ul {
      width: 100%;
      text-align: center;
      li {
        display: inline-block;
        a {
          display: block;
          padding: 5px 15px;
          color: #fff;
          font-family: $wf1;
          font-size: 18px;
          font-weight: bold;

          &.contact {
            .ico {
              display: none;
            }
          }
        }
      }
    }
  }
  .copy {
    padding-top: 30px;
    text-align: center;
    p {
      color: #fff;
      font-size: 12px;
      font-family: $wf1;
    }
  }
}
// SUB CONTENTS
.sub-page {
  background: #000;
  .loading{
    display: none;
  }
  .contents {
    transition: background-color $easeInSine 700ms;

    &.show{
      background-color: #fff;
    }
    .main-wrap{
      position: relative;
      padding: 80px 50px;
      overflow: hidden;
      height: 500px;
      &.loaded{
        .bg{
          
        }
      }
      .bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height:100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
        &:before{
          content: '';
          background: rgba(0,0,0,0.4);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        
      }
      
    }
    
  
    .sub-contents {
      padding: 80px 50px;
      &.bg-blue{
        background: #e1f4ff;
      }
      &.no-title {
        padding: 0px 50px 80px;
      }
      .news-wrap{
        background: #fff;
      }
      &.bg-gra{
        background: url(../img/granji.svg) repeat-y center center #f7f7f7;
        background-size: 100% auto;
      }
      .head-title{
        margin-bottom: 60px;
        text-align: center;
        h1{
          font-size: 50px;
          line-height: 50px;
          font-family: $wf1;
          margin-bottom: 10px;
        }
        h2{
          font-size: 10px;
        }
      }
      .inner {
        margin: 0 auto;
        min-width: 800px;
        .concept-wrap{
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          padding: 80px;
          h1 {
            margin-bottom: 30px;
            text-align: center;
            span {
              display: inline-block;
              padding: 10px;
              background: $mainC;
              color: #fff;
              font-family: $wf1;
              font-size: 60px;
              line-height: 60px;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: #fff;
                width: 100%;
                height: 100%;
                transform: scaleX(1);
                transform-origin: right top;
                transition: transform $easeInSine 500ms 800ms;
              }
            }
            &.loaded {
              span {
                &:before {
                  transform: scaleX(0);
                }
              }
            }
          }
          h2 {
            text-align: center;
            span {
              display: inline-block;
              padding: 10px;
              background: #fff;
              color: $mainC;
              font-size: 23px;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: $mainC;
                width: 100%;
                height: 100%;
                transform: scaleX(1);
                transform-origin: left top;
                transition: transform $easeInSine 500ms 1200ms;
              }
            }
            &.loaded {
              span {
                &:before {
                  transform: scaleX(0);
                }
              }
            }
          }
        }
        .works-wrap{
          .works-dl{
            position: relative;
            dt{
                display: flex;
                background: #000;
                color: #fff;
                font-family: $wf1;
                width: 180px;
                height: 180px;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                position: absolute;
            }
            dd{
              padding-top: 80px;
              padding-left: 80px;
              .worls-list{
                display: flex;
                flex-wrap: wrap;
                li{
                  width: calc(100% / 3);
                  box-sizing: border-box;
                  padding: 0 15px;
                  margin-bottom: 30px;
                  opacity: 0;
                  transition: opacity $easeInSine 500ms;

                  &.loaded{
                    opacity: 1;
                  }
                  .photo{
                    width: 100%;
                    margin-bottom: 15px;
                    text-align: center;
                    img{
                      width: 100%;
                      height: auto;
                    }
                  }
                  .tite{
                    font-family: $wf1;
                    font-size: 18px;
                    span{
                      font-family: $fg;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
        .hmd-wrap{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .message{
            width:500px;
            p{
              margin-bottom: 5px;
              &.title{
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
              }
            }
          }
          .photo{
            width: 500px;
            img{
              width: 100%;
              height: atuo;
            }
          }
          
        }
        .category-wrap {
          margin-bottom: 50px;
          .category-list {
            text-align: center;
            li {
              text-align: center;
              padding: 15px;
              display: inline-block;
              a {
                display: inline-block;
                padding: 15px 30px;
                background: $linkC;
                color: #fff;
                &:hover {
                  background: $colorY;
                }
              }
            }
          }
        }
        .company-table {
          width: 100%;
          margin-bottom: 30px;
          tr {
            border-bottom: 1px solid #eee;
            th {
              padding: 15px;
              text-align: center;
            }
            td {
              padding: 15px;
            }
          }
        }
        .map-wrap {
          iframe {
            width: 100%;
          }
        }
        .sekoujirei-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          opacity: 0;
          transition: opacity $easeInSine 600ms 300ms;
          &.loaded {
            opacity: 1;
          }
          li {
            width: calc(100% / 3);
            &:nth-child(even) {
              background: #f5f5f5;
            }
            a {
              display: block;
              padding: 30px;
              background: #fff;
              transition: background-color $easeInSine 300ms;
              &:hover {
                background: #f5f5f5;
              }
              .detail {
                .photo {
                  margin-bottom: 30px;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
                .date {
                  font-size: 12px;
                  margin-bottom: 5px;
                }
                .cat {
                  background: $mainC;
                  padding: 5px;
                  font-size: 14px;
                  margin-bottom: 10px;
                  color: #fff;
                  display: inline-block;
                }
                .title {
                  font-weight: bold;
                }
              }
            }
          }
        }
        .entry-detail {
          .entry-header {
            padding: 30px;
            margin-bottom: 30px;
            box-sizing: border-box;
            position: relative;
            background: #fff;
            
            .title {
              font-size: 24px;
              font-weight: bold;
            }
            .date {
              font-size: 12px;
            }
            .cat {
              font-size: 12px;
              font-weight: bold;
              background: #000;
              color: #fff;
              padding: 2px 5px;
              text-align: center;
              position: absolute;
              right: 30px;
              bottom: 30px;
            }
          }
          .entry {
            background: #fff;
            padding: 30px;
            p {
            }
            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
        .item-push-list{
          li{
            margin-bottom: 60px;
            &:nth-child(even){
              .detail{
                flex-direction: row-reverse;
                .photo{}
                
              }
              &.loaded{
                .detail{
                  .push-text-wrap{
                    transform:translateX(60px);
                    // padding-left: calc(100% - 800px);
                  }
                }
              }
            }
            &.loaded{
              .detail{
                .photo{
                  opacity: 1;
                  transform:translateY(0px);
                }
                .push-text-wrap{
                  transform:translateX(-140px);
                  .push-title{
                    span{
                      background: #000;
                    }
                  }
                  .text{
                    span{
                      background: #fff;
                    }
                  }
                }
              }
            }
            .detail{
              display: flex;
              align-items: center;
              position: relative;
              .photo{
                width: 1000px;
                transition: all $easeInSine 500ms 700ms;
                opacity: 0;
                transform:translateY(80px);
                img{
                  max-width: 100%;
                  height: auto;
                }
              }
              .push-text-wrap{
                width: 1000px;
                transform:translateX(0px);
                transition: transform $easeInSine 500ms 700ms;
                .push-title{
                  font-weight: bold;
                  font-size: 18px;
                  margin-bottom: 20px;
                  span{
                    background: transparent;
                    color: #fff;
                    padding: 10px;
                    display: inline-block;
                    transition: background-color $easeInSine 1000ms 500ms;

                  }
                }
                .text{
                  span{
                    display: inline-block;
                    background: transparent;
                    padding: 10px;
                    transition: background-color $easeInSine 1000ms 500ms;

                  }
                }
              }
            }
          }
        }
        .item-table{
          width:100%;
			    tr{
            th,td{
              transition: opacity $easeInSine 500ms 500ms;
              opacity: 0;
            }
            &.loaded{
              th,td{
                opacity: 1;
              }
            }
				    th{
              padding: 30px 15px;
              background: #000;
              color: #fff;
              border: 4px solid #fff;
              white-space: nowrap;
              width: 200px;
            }
            td{
              padding: 30px 15px;
              background: #e7e7e7;
              border: 4px solid #fff;
              &.item-name,
              &.price{
                font-family: $wf1;
                font-size: 24px;
                span{
                  font-size: 11px;
                }
              }
            }
          }
        }
        .gallery-wrap{
          display: flex;
          flex-wrap: wrap;
          li{
            width: calc(100% / 3);
            padding: 0 15px 0;
            margin-bottom: 30px;
            a{
              display: block;
              transition: all 300ms;
              opacity: 1;
              &:hover{
                opacity: 0.8;
              }
              img{
                width: 100%;
              }
            }
          }
        }
      }
    }
    .contact-wrap {
      background: #f7f7f7;
      .inner {
        .sub-header-title {
          margin-bottom: 30px;
          h1 {
            font-size: 18px;
            text-align: center;
            font-weight: bold;
          }
        }
        .contact-list {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 30px;
          &.loaded{
            li{
              a{
                transform:translateY(0px);
                opacity: 1;
              }
            }
          }
          li {
            width: 380px;
            padding: 0 15px;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              background: $mainC;
              color: #fff;
              transition: all 300ms;
              padding: 15px;
              box-sizing: border-box;
              height: 100px;
              font-size: 18px;
              transition: all $easeInSine 500ms 400ms;
              transform:translateY(30px);
              opacity: 0;
              font-family: $wf1;
              i {
                width: 40px;
                display: inline-block;
                margin-right: 10px;
                img {
                  width: 100%;
                }
              }
              &.tel {
              }
              &.mail {
              }
              &:hover {
                background: $normalC;
              }
            }
          }
        }
      }
    }
  }
  section {
    .inner {
      min-width: 1000px;
      max-width: 1400px;
    }
  }
}

.more {
  display: block;
  width: 300px;
  height: 60px;
  background: $mainC;
  color: #fff;
  font-size: 22px;
  font-family: $wf1;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
  margin: 50px auto;
  &:hover {
    background: $linkC;
    color: #fff;
  }
}


form p {
  margin-bottom: 30px;
  border-bottom: solid #eee 1px;
  padding-bottom: 10px;
}
form p select {
  display: inline;
}
form p textarea {
  vertical-align: top;
}
.attention {
  color: #f00;
}

form .back{
  display: inline-block;
    padding: 15px 50px;
    box-sizing: border-box;
    background: #ccc;
    font-size: 16px;
}
form .confirm{
  display: inline-block;
  padding: 15px 50px;
  box-sizing: border-box;
  background: #333333;
  font-size: 16px;
  color: #fff;
  vertical-align: top;
  font-weight: bold;
}

