@charset "utf-8";

.pc {
  display: none !important;
}
.mb-l {
  margin-bottom: 30px;
}
.mb-m {
  margin-bottom: 15px;
}
.mb-s {
  margin-bottom: 5px;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1;
  transition: opacity $easeInSine 1800ms;
  opacity: 1;
  .progress {
    position: absolute;
    width: 0;
    height: 2px;
    background: rgba(254, 233, 202, 0.5);
    top: calc(50% - 1px);
    left: 0;
    transition: all $easeInSine 1800ms;
    &:after {
      content: "";
      position: absolute;
      top: -3px;
      right: -3px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: rgba(254, 233, 202, 1);
      filter: blur(2px);
    }
    &.start{
      width: 100%;
    }
  }
  
  &.end {
    .progress {
      opacity: 0;
    }
  }
  &.hide {
    z-index: -1;
    opacity: 0;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0px;
  height: 80px;
  width: 100%;
  // background: #fff;
  background: url(../img/granji.svg) repeat-y center center #f7f7f7;
  background-size: 100% auto;
  padding: 15px;
  box-sizing: border-box;
  opacity: 0;
  z-index: 3;
  transition: opacity $easeInSine 1500ms;
  &.show {
    opacity: 1;
  }
  &.bg {
    // background: #111;
  }
  .logo {

    svg {
      width: 40px;
    }
  }
}

.gnav {
  position: fixed;
  width: 100%;
  height: 100%;
  transition: transform $easeInSine 500ms;
  transform: translateY(-100%);
  box-sizing: border-box;
  background: $bgB;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 11;
  ul {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    // background: $normalC;
    z-index: 3;
    padding: 30px 15px 15px;

    li {
      position: relative;
      // border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      a {
        padding: 15px;
        color: #fff;
        position: relative;
        display: block;
        overflow: hidden;
        background: transparent;
        transition: all $easeInSine 300ms;
        font-family: $wf1;
        font-size: 24px;
        text-align: center;
        &.contact {
          background: #2d1304;
          color: #fff;
          text-align: center;
          margin-top: 15px;
          &:hover {
            background: $colorB;
            &:after {
              opacity: 0;
            }
          }
          .ico {
            width: 20px;
            margin-right: 10px;
            display: inline-block;
            img {
              width: 100%;
              vertical-align: sub;
            }
          }
        }
        &.sns {
          .ico {
            text-align: center;
            svg {
              width: 40px;
              height: auto;
              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}
.menu-trigger {
  position: fixed;
  right: 15px;
  top: 30px;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  z-index: 11;
  transition: all $easeInSine 500ms;
  cursor: pointer;
  &.show {
    opacity: 1;
  }
  span {
    width: 30px;
    height: 2px;
    background: $bgB;
    position: relative;
    display: block;
    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background: $bgB;
      position: absolute;
      top: 10px;
      transition: transform $easeInSine 500ms;
      transform: rotate(0);
    }
    &:after {
      content: "";
      width: 100%;
      height: 2px;
      background: $bgB;
      position: absolute;
      top: 20px;
      transition: transform $easeInSine 500ms;
      transform: rotate(0);
    }
  }
}
.menu-show {
  .menu-trigger {
    z-index: 12;
    span {
      background: transparent;
      &:before {
        transform: rotate(45deg);
        background: #fff;
      }
      &:after {
        transform: rotate(-45deg);
        top: 11px;
        background: #fff;
      }
    }
  }
  .gnav {
    transform: translateY(0);
  }
}
body {
  background: #000;
  line-height: 1.8;
  color: $normalC;
  font-size: 13px;
  font-family: $fg;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;

  a {
    text-decoration: none;
    color: $normalC;
  }
}
.first-wrap {
  position: relative;
  // overflow: hidden;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity $ease 5000ms;
  &.show {
    opacity: 1;
  }
  .first-view {
    position: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100%;
    overflow: hidden;
    
    img {
      width: auto;
      height: 100vh;
      transform:translateX(-20%);
    }
    .first-logo {
      position: absolute;
      width: 300px;
      height: 378px;
      left: calc(50% - 150px);
      top: calc(50% - 164px);
      mix-blend-mode: overlay;
      transform:scale(0.5);
      svg {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        top: 0;
      }
      .bg {
        transition: all $easeInSine 1000ms;
        opacity: 0;
        transform: scale(1.3);
        &.show {
          opacity: 1;
          transform: scale(1);
        }
        svg {
          path {
            fill: none;
            stroke: rgba(0, 0, 0, 0.7);
            stroke-miterlimit: 10;
          }
        }
      }
      .f-1 {
        transition: all $easeInSine 300ms 1000ms;
        transform: translateY(-20px);
        opacity: 0;
        &.show {
          opacity: 1;
          transform: translateY(0px);
        }
        svg {
          path {
            fill: rgba(0, 0, 0, 0.7);
          }
        }
      }
      .f-2 {
        transition: all $easeInSine 1800ms 1000ms;
        transform: translateY(20px);
        opacity: 0;
        &.show {
          opacity: 1;
          transform: translateY(0px);
        }
        svg {
          path {
            fill: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }

  }
}
.top-contents {
  position: relative;
  box-sizing: border-box;

  .top-common {
    padding: 60px 10px;
    box-sizing: border-box;
    position: relative;
    color: #fff;
    .head-line {
      color: $normalC;
      margin-bottom: 20px;
      text-align: center;

      &.loaded {
        h1 {
          span {
            transform: translateY(0);
            opacity: 1;
            &:after {
              transform: scaleX(1);
            }
          }
        }
        p {
          transform: translateY(0);
          opacity: 1;
        }
      }
      h1 {
        font-family: $wf1;
        font-size: 40px;
        letter-spacing: 0.1em;
        margin-bottom: 5px;
        margin-right: 10px;
        font-weight: bold;
        line-height: 40px;
        overflow: hidden;

        span {
          padding: 0px 5px 5px 5px;
          display: inline-block;
          position: relative;
          opacity: 0;
          transform: translateY(60px);
          transform-origin: center bottom;
          transition: transform $easeInSine 300ms 300ms;
          &:after {
            content: "";
            width: 100%;
            height: 4px;
            display: block;
            bottom: 0;
            left: 0;
            position: absolute;
            background: $mainC;
            transform: scaleX(0);
            // transform-origin: left top;
            transition: transform $easeInSine 500ms 800ms;
          }
        }
        &.c-w {
          color: #fff;
          span {
            &:after {
              background: #fff;
            }
          }
        }
      }
      p {
        transform: translateY(30px);
        transition: all $easeInSine 600ms 600ms;
        opacity: 0;
        &.c-w {
          color: #fff;
        }
      }
    }

    .inner {
      margin: 0 auto;
      width: 96%;
      .main-title {
        // color: $linkC;
        color: #fff;
        font-size: 26px;
        letter-spacing: 0.1em;
        margin-bottom: 15px;
        font-weight: normal;
      }
    }
  }
  .concept-wrap {
    background: url(../img/c-bg-02.jpg) no-repeat center center;
    transition: all $easeInSine 500ms 800ms;
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.9);
      mix-blend-mode: overlay;
    }
    &.loaded {
    }
    .inner {
      position: relative;
      z-index: 1;
      h1 {
        margin-bottom: 30px;
        span {
          display: inline-block;
          padding: 10px;
          background: $mainC;
          color: #fff;
          font-family: $wf1;
          font-size: 60px;
          line-height: 60px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            width: 100%;
            height: 100%;
            transform: scaleX(1);
            transform-origin: right top;
            transition: transform $easeInSine 500ms 800ms;
          }
        }
        &.loaded {
          span {
            &:before {
              transform: scaleX(0);
            }
          }
        }
      }
      h2 {
        span {
          display: inline-block;
          padding: 10px;
          background: #fff;
          color: $mainC;
          font-size: 23px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: $mainC;
            width: 100%;
            height: 100%;
            transform: scaleX(1);
            transform-origin: left top;
            transition: transform $easeInSine 500ms 1200ms;
          }
        }
        &.loaded {
          span {
            &:before {
              transform: scaleX(0);
            }
          }
        }
      }
    }
  }
}
// COMMON CONTENTS
.items-wrap {
  .inner {
    .items-list {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 20px;
        &.loaded {
          a {
            .deital {
              .photo {
                &:before {
                  transform: scaleX(0);
                  transition: transform $easeInSine 500ms 400ms;
                }
              }
            }
          }
        }
        a {
          display: block;
          .deital {
            .photo {
              img {
                width: 100%;
                height: auto;
              }
              position: relative;
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: $mainC;
                width: 100%;
                height: 100%;
                transform: scaleX(1);
                transform-origin: right top;
                transition: transform $easeInSine 500ms 1200ms;
              }
            }
            .detial-text {
              .title {
                font-family: $wf1;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 15px;
              }
              .price {
                font-family: $wf1;
                font-weight: bold;
                text-align: right;
                span {
                  font-weight: normal;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.news-wrap {
  background: #e1f4ff;

  .inner {
    .news-list {
      li {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #000;
        padding: 10px;
        margin-bottom: 10px;
        opacity: 0;
        transition: transform $easeInSine 500ms 800ms;
        &.loaded {
          opacity: 1;
        }
        .date {
          width: 80px;
          display: inline-block;
          padding: 5px;
          font-family: $wf1;
          font-weight: bold;
          font-size: 14px;
          background: #000;
          color: #fff;
        }
        .title {
          width: calc(100% - 100px);
          a {
          }
        }
      }
    }
  }
}
.footer {
  position: relative;
  padding: 50px 10px;
  background: #000;

  .page-top {
    display: none;
    a {
      span {
      }
    }
  }
  .footer-inner {
    width: 96%;
    display: flex;
    margin: 0 auto;
    ul {
      width: 100%;
      text-align: center;
      li {
        display: inline-block;
        a {
          display: block;
          padding: 5px 15px;
          color: #fff;
          font-family: $wf1;
          font-size: 18px;
          font-weight: bold;

          &.contact {
            .ico {
              display: none;
            }
          }
        }
      }
    }
  }
  .copy {
    padding-top: 30px;
    text-align: center;
    p {
      color: #fff;
      font-size: 12px;
      font-family: $wf1;
    }
  }
}
// SUB CONTENTS
.sub-page {
  background: #000;
  .loading{
    display: none;
  }
  .contents {
    transition: background-color $easeInSine 700ms;

    &.show{
      background-color: #fff;
    }
    .main-wrap{
      position: relative;
      padding: 40px 10px;
      overflow: hidden;
      height: 300px;
      &.loaded{
        .bg{
          
        }
      }
      .bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height:100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: auto 100%;
        &:before{
          content: '';
          background: rgba(0,0,0,0.4);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        
      }
      .main-inner{
        text-align: right;
        padding-top: 100px;
        .head-line {
          color: $normalC;
          text-align: center;
          &.loaded {
            h1 {
              span {
                transform: translateY(0);
                opacity: 1;
                &:after {
                  transform: scaleX(1);
                }
              }
            }
            p {
              transform: translateX(0);
              opacity: 1;
            }
          }
          h1 {
            font-family: $wf1;
            font-size: 40px;
            letter-spacing: 0.1em;
            margin-bottom: 15px;
            font-weight: bold;
            line-height: 40px;
            overflow: hidden;
    
            span {
              padding: 0px 5px 5px 5px;
              display: inline-block;
              position: relative;
              opacity: 0;
              transform: translateY(80px);
              transform-origin: center bottom;
              transition: transform $easeInSine 300ms 300ms;
              &:after {
                content: "";
                width: 100%;
                height: 4px;
                display: block;
                bottom: 0;
                left: 0;
                position: absolute;
                background: $mainC;
                transform: scaleX(0);
                // transform-origin: left top;
                transition: transform $easeInSine 800ms 800ms;
              }
            }
            &.c-w {
              color: #fff;
              span {
                &:after {
                  background: #fff;
                }
              }
            }
          }
          p {
            transform: translateX(30px);
            transition: all $easeInSine 600ms 600ms;
            opacity: 0;
            &.c-w {
              color: #fff;
            }
          }
        }
      }
    }
    
  
    .sub-contents {
      padding: 40px 10px;
      &.bg-blue{
        background: #e1f4ff;
      }
     
      .news-wrap{
        background: #fff;
      }
      &.bg-gra{
        background: url(../img/granji.svg) repeat-y center center #f7f7f7;
        background-size: 100% auto;
      }
      .head-title{
        margin-bottom: 30px;
        text-align: center;
        h1{
          font-size: 30px;
          line-height: 30px;
          font-family: $wf1;
          margin-bottom: 10px;
        }
        h2{
          font-size: 10px;
        }
      }
      .inner {
        margin: 0 auto;
        width: 96%;
        .concept-wrap{
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          padding: 20px;
          h1 {
            margin-bottom: 30px;
            text-align: center;
            span {
              display: inline-block;
              padding: 10px;
              background: $mainC;
              color: #fff;
              font-family: $wf1;
              font-size: 30px;
              line-height: 30px;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: #fff;
                width: 100%;
                height: 100%;
                transform: scaleX(1);
                transform-origin: right top;
                transition: transform $easeInSine 500ms 800ms;
              }
            }
            &.loaded {
              span {
                &:before {
                  transform: scaleX(0);
                }
              }
            }
          }
          h2 {
            text-align: center;
            span {
              display: inline-block;
              padding: 10px;
              background: #fff;
              color: $mainC;
              font-size: 23px;
              position: relative;
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: $mainC;
                width: 100%;
                height: 100%;
                transform: scaleX(1);
                transform-origin: left top;
                transition: transform $easeInSine 500ms 1200ms;
              }
            }
            &.loaded {
              span {
                &:before {
                  transform: scaleX(0);
                }
              }
            }
          }
        }
        .works-wrap{
          .works-dl{
            position: relative;
            dt{
                display: flex;
                background: #000;
                color: #fff;
                font-family: $wf1;
                width: 120px;
                height: 120px;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                margin:15px auto 30px;
            }
            dd{
              .worls-list{
                display: flex;
                flex-wrap: wrap;
                li{
                  width: 50%;
                  box-sizing: border-box;
                  padding: 0 15px;
                  margin-bottom: 30px;
                  opacity: 0;
                  transition: opacity $easeInSine 500ms;

                  &.loaded{
                    opacity: 1;
                  }
                  .photo{
                    width: 100%;
                    margin-bottom: 15px;
                    text-align: center;
                    img{
                      width: 100%;
                      height: auto;
                    }
                  }
                  .tite{
                    font-family: $wf1;
                    font-size: 18px;
                    span{
                      display: block;
                      font-family: $fg;
                      font-size: 11px;
                    }
                  }
                }
              }
            }
          }
        }
        .hmd-wrap{
          .message{
            margin-bottom: 30px;
            p{
              margin-bottom: 5px;
              &.title{
                font-size: 18px;
                font-weight: bold;
                margin: 10px 0;

              }
            }
          }
          .photo{
            margin-bottom: 30px;
            img{
              width: 100%;
              height: auto;
            }
          }
          
        }
        .category-wrap {
          margin-bottom: 30px;
          .category-list {
            text-align: center;
            li {
              text-align: center;
              padding: 15px;
              display: inline-block;
              a {
                display: inline-block;
                padding: 15px 30px;
                background: $linkC;
                color: #fff;
                &:hover {
                  background: $colorY;
                }
              }
            }
          }
        }
        .company-table {
          width: 100%;
          margin-bottom: 30px;
          tr {
            border-bottom: 1px solid #eee;
            th {
              padding: 15px;
              text-align: center;
            }
            td {
              padding: 15px;
            }
          }
        }
        .map-wrap {
          iframe {
            width: 100%;
          }
        }
        .sekoujirei-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          opacity: 0;
          transition: opacity $easeInSine 600ms 300ms;
          &.loaded {
            opacity: 1;
          }
          li {
            width: calc(100% / 3);
            &:nth-child(even) {
              background: #f5f5f5;
            }
            a {
              display: block;
              padding: 30px;
              background: #fff;
              transition: background-color $easeInSine 300ms;
              &:hover {
                background: #f5f5f5;
              }
              .detail {
                .photo {
                  margin-bottom: 30px;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
                .date {
                  font-size: 12px;
                  margin-bottom: 5px;
                }
                .cat {
                  background: $mainC;
                  padding: 5px;
                  font-size: 14px;
                  margin-bottom: 10px;
                  color: #fff;
                  display: inline-block;
                }
                .title {
                  font-weight: bold;
                }
              }
            }
          }
        }
        .entry-detail {
          .entry-header {
            // background: #fefefe;
            // padding: 30px;
            margin-bottom: 30px;
            position: relative;
            .title {
              color: $colorG;
              font-size: 24px;
              border-bottom: 1px solid $colorG;
              padding-bottom: 5px;
              margin-bottom: 2px;
            }
            .date {
              font-size: 12px;
            }
            .cat {
              font-size: 12px;
              background: $colorG;
              color: #fff;
              padding: 2px 5px;
              text-align: center;
              position: absolute;
              right: 0px;
              bottom: -1px;
            }
          }
          .entry {
            border: 1px solid $colorG;
            background: #fff;
            padding: 30px;
            p {
            }
            img {
              max-width: 100%;
              height: auto;
            }
          }
        }
        .item-push-list{
          li{
            margin-bottom: 40px;
            &:nth-child(even){
              
              &.loaded{
                .detail{
                  .push-text-wrap{
                    // padding-left: calc(100% - 800px);
                  }
                }
              }
            }
            &.loaded{
              .detail{
                .photo{
                  opacity: 1;
                  transform:translateY(0px);
                }
                .push-text-wrap{
                  .push-title{
                    span{
                      background: #000;
                    }
                  }
                  .text{
                    span{
                      background: #fff;
                    }
                  }
                }
              }
            }
            .detail{
              
              position: relative;
              .photo{
                width: 100%;
                transition: all $easeInSine 500ms 700ms;
                opacity: 0;
                transform:translateY(80px);
                img{
                  width: 100%;
                  height: auto;
                }
              }
              .push-text-wrap{
                width: 100%;
                transform:translateY(-20px);
                transition: transform $easeInSine 500ms 700ms;
                .push-title{
                  font-weight: bold;
                  font-size: 18px;
                  margin-bottom: 20px;
                  span{
                    background: transparent;
                    color: #fff;
                    padding: 10px;
                    display: inline-block;
                    transition: background-color $easeInSine 1000ms 500ms;
                  }
                }
                .text{
                  span{
                    display: inline-block;
                    background: transparent;
                    padding: 10px;
                    transition: background-color $easeInSine 1000ms 500ms;

                  }
                }
              }
            }
          }
        }
        .item-table{
          width:100%;
			    tr{
            th,td{
              transition: opacity $easeInSine 500ms 500ms;
              opacity: 0;
              // display: block;
            }
            &.loaded{
              th,td{
                opacity: 1;
              }
            }
				    th{
              padding: 20px 15px;
              background: #000;
              color: #fff;
              border: 4px solid #fff;
              white-space: nowrap;
            }
            td{
              padding: 20px 15px;
              background: #e7e7e7;
              border: 4px solid #fff;
              &.item-name,
              &.price{
                font-family: $wf1;
                font-size: 20px;
                span{
                  font-size: 11px;
                }
              }
            }
          }
        }
        .gallery-wrap{
          display: flex;
          flex-wrap: wrap;
          li{
            width: 50%;
            padding: 0 15px;
            margin-bottom: 30px;
            a{
              display: block;
              transition: all 300ms;
              opacity: 1;
              &:hover{
                opacity: 0.8;
              }
              img{
                width: 100%;
              }
            }
          }
        }
      }
    }
    .contact-wrap {
      background: #f7f7f7;
      .inner {
        .sub-header-title {
          margin-bottom: 30px;
          h1 {
            font-size: 18px;
            text-align: center;
            font-weight: bold;
          }
        }
        .contact-list {
          margin-bottom: 30px;
          &.loaded{
            li{
              a{
                transform:translateY(0px);
                opacity: 1;
              }
            }
          }
          li {
            width: 100%;
            padding: 0 15px;
            margin-bottom: 15px;
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              background: $mainC;
              color: #fff;
              transition: all 300ms;
              padding: 15px;
              box-sizing: border-box;
              height: 100px;
              font-size: 18px;
              transition: all $easeInSine 500ms 400ms;
              transform:translateY(30px);
              opacity: 0;
              i {
                width: 40px;
                display: inline-block;
                margin-right: 10px;
                img {
                  width: 100%;
                }
              }
              &.tel {
              }
              &.mail {
              }
              &:hover {
                background: $normalC;
              }
            }
          }
        }
      }
    }
  }
  
}

.more {
  display: block;
  width: 300px;
  height: 60px;
  background: $mainC;
  color: #fff;
  font-size: 22px;
  font-family: $wf1;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
  margin: 50px auto;
  &:hover {
    background: $linkC;
    color: #fff;
  }
}


form p em{
  display:block;
}
form input[type="text"],
form input[type="email"] {
  max-width: 90%;
}
form textarea {
  max-width: 90%;
}
